import { ListingStatus } from 'types';

export const getListingStatusDisplayName = (listingStatus: ListingStatus) => {
  switch (listingStatus) {
    case ListingStatus.Rejected:
      return 'Rejected';
    case ListingStatus.Ordered:
      return 'Ordered';
    case ListingStatus.Pending:
      return 'Pending review';
    case ListingStatus.Archived:
      return 'Archived';
    case ListingStatus.Published:
      return 'Live';
    case ListingStatus.Suspended:
      return 'Suspended';
    case ListingStatus.Draft:
      return 'Draft';
    default:
      return '';
  }
};
