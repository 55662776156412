import useSWR from 'swr';

import { clientApiGet } from 'lib/api_helper';
import { apiUrls } from 'lib/api_urls';
import { SelectedSaleData } from 'types';

export const useGetSelectedSale = (isEnabled: boolean) => {
  const { data, isValidating } = useSWR<SelectedSaleData>(
    isEnabled ? apiUrls.selectedSale : null,
    url =>
      clientApiGet(url, {
        extraHeaders: [
          {
            name: 'x-plasmic-api-cms-tokens',
            value: `${process.env.NEXT_PUBLIC_PLASMIC_CMS_ID}:${process.env.NEXT_PUBLIC_PLASMIC_TOKEN}`
          }
        ]
      }),
    { revalidateOnFocus: false }
  );

  return { data, isValidating };
};
