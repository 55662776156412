import Script from 'next/script';

import { checkoutSessionId } from 'consts/api';

export const Signifyd = () => (
  <Script
    defer
    type='text/javascript'
    id='sig-api'
    data-order-session-id={checkoutSessionId}
    strategy='lazyOnload'
    src='https://cdn-scripts.signifyd.com/api/script-tag.js'
  />
);
