import { Listing } from './listing';
import { PaymentTransaction } from './paymentTransaction';

export enum OFFER_STATUS {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  AWAITING_SELLER_RESPONSE = 'AWAITING_SELLER_RESPONSE',
  AWAITING_BUYER_RESPONSE = 'AWAITING_BUYER_RESPONSE',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  COMPLETED = 'COMPLETED',
  ORDERED = 'ORDERED'
}

export const ACTIVE_OFFER_STATUS = [
  OFFER_STATUS.AWAITING_BUYER_RESPONSE,
  OFFER_STATUS.AWAITING_SELLER_RESPONSE,
  OFFER_STATUS.ACCEPTED
];

export enum INITIATOR {
  Buyer = 'BUYER',
  Seller = 'SELLER'
}

export interface Offer {
  id: number;
  buyerProfile: {
    id: number;
  };
  listing: Listing;
  cancellationReason: string;
  priceCents: number;
  created: string;
  updated: string;
  expiration: string;
  status: OFFER_STATUS;
  sellerProfile: {
    id: number;
  };
  transaction: PaymentTransaction;
  priceHistory: { priceCents: number; initiator: INITIATOR }[];
  apiVersion: number;
  mostRecentPriceCents: number;
}
