export const FOOTER_SECTIONS = [
  {
    title: 'Company',
    links: [
      { name: 'Careers', href: 'https://www.useparallel.com/bezel/careers' },
      { name: 'Support', href: 'https://support.getbezel.com' },
      { name: 'Journal', href: 'https://getbezel.com/journal' },
      { name: 'The App', href: 'https://getbezel.com/app' },
      { name: 'Email us', href: 'mailto:team@getbezel.com?subject=Hi!' },
      { name: 'Press', href: 'mailto:press@getbezel.com' },
      { name: 'Sitemap', href: '/sitemap' }
    ]
  },
  {
    title: 'Social',
    links: [
      { name: 'LinkedIn', href: 'https://www.linkedin.com/company/getbezel' },
      { name: 'YouTube', href: 'https://www.youtube.com/@getbezel' },
      { name: 'Instagram', href: 'https://www.instagram.com/getbezel/' },
      { name: 'TikTok', href: 'https://www.tiktok.com/@getbezel' },
      { name: 'Twitter', href: 'http://twitter.com/getbezel' }
    ]
  },
  {
    title: 'Legal',
    links: [
      { name: 'Terms', href: 'https://www.getbezel.com/terms' },
      { name: 'Privacy Policy', href: 'https://www.getbezel.com/privacy' },
      { name: 'Buyer Protection Policy', href: 'https://www.getbezel.com/buyer-policy' },
      { name: 'Seller Policy', href: 'https://www.getbezel.com/seller-policy' },
      { name: 'Authentication Policy', href: 'https://www.getbezel.com/authentication-policy' },
      { name: 'Seller/OEM Warranty Policy', href: 'https://www.getbezel.com/seller-oem-warranty-policy' },
      { name: 'Copyright & IP Policy', href: 'https://www.getbezel.com/copyright-and-intellectual-property-policy' },
      { name: 'Marketplace Tax Collection Policy', href: 'https://www.getbezel.com/marketplace-tax-collection-policy' },
      { name: 'BezelCare Policy', href: 'https://www.getbezel.com/bezelcare-policy' },
      { name: 'Return Policy', href: 'https://www.getbezel.com/return-policy' },
      { name: 'Bezel Credit Policy', href: 'https://www.getbezel.com/bezel-credit-policy' }
    ]
  }
];
