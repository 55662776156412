import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import useSWR from 'swr';

import { SearchBar } from 'components/search/SearchBar';
import { SearchResults } from 'components/search/SearchResults';
import { COLORS } from 'consts';
import { useTheme } from 'context/theme';
import { useDebounce } from 'hooks/useDebounce';
import { clientApiGet } from 'lib/api_helper';
import styles from 'styles/Search.module.scss';
import { PaginatedCatalogSearch } from 'types';

/**
 * Outer search component, handles retrieving search results and rendering them appropriately.
 */
export const Search: FC = () => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);
  const [onSearchFocused, setOnSearchFocused] = useState(false);

  const { isDark } = useTheme();

  const { data: searchResults } = useSWR<PaginatedCatalogSearch>(
    debouncedQuery.length > 2 ? `/api/search/search?query=${debouncedQuery}&available=${true}` : null,
    clientApiGet
  );

  const searchContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutsideSearch = (event: MouseEvent) => {
      if (onSearchFocused && searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
        setOnSearchFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideSearch);

    return () => document.removeEventListener('mousedown', handleClickOutsideSearch);
  }, [onSearchFocused, setOnSearchFocused]);

  const onNavigate = () => {
    setQuery('');
    setOnSearchFocused(false);
  };

  const updateQuery = (queryValue: string) => {
    setOnSearchFocused(queryValue.length > 2);
    setQuery(queryValue);
  };

  return (
    <>
      <div
        className={classNames('position-absolute', styles.searchWrapper, {
          'top-0': onSearchFocused
        })}
      >
        {onSearchFocused && (
          <div
            className='vh-100 vw-100 position-fixed bg-black opacity-50'
            style={{
              zIndex: 1031,
              top: 0,
              left: 0
            }}
          />
        )}
        <div
          ref={searchContainerRef}
          style={{
            zIndex: 1032,
            bottom: onSearchFocused ? 12 : 0,
            backgroundColor: isDark ? COLORS.primaryDark : undefined
          }}
          className={classNames('position-relative py-3 pe-3', {
            'shadow rounded-3': onSearchFocused,
            'bg-light': onSearchFocused && !isDark
          })}
        >
          <SearchBar query={query} setQuery={updateQuery} setHasNavigated={onNavigate} />
          {onSearchFocused && (
            <SearchResults
              searchResults={searchResults}
              setHasNavigated={onNavigate}
              className='mt-2 ps-3'
              query={query}
            />
          )}
        </div>
      </div>
    </>
  );
};
