import { FaMagnifyingGlass } from '@react-icons/all-files/fa6/FaMagnifyingGlass';
import { useRouter } from 'next/router';

import { Link } from 'components/common/Link';
import { COLORS } from 'consts';
import { useTheme } from 'context/theme';
import { pageRoutes } from 'lib/routes';

export const MobileSearch = () => {
  const router = useRouter();
  const { isDark } = useTheme();

  const isOnExplorePage = router.pathname.includes('explore');

  if (isOnExplorePage) {
    return null;
  }

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <Link href={{ pathname: pageRoutes.explore(), query: { autofocus: true } }}>
        <FaMagnifyingGlass className='position-relative text-primary' fill={isDark ? COLORS.primaryLight : undefined} />
      </Link>
    </div>
  );
};
