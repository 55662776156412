import NextLink from 'next/link';

export type LinkProps = React.ComponentProps<typeof NextLink>;

const isPrefetchDisabled = true;

export const Link: React.FC<LinkProps> = ({ children, ...rest }) => {
  if (isPrefetchDisabled) {
    return (
      <NextLink prefetch={false} {...rest}>
        {children}
      </NextLink>
    );
  }

  return <NextLink {...rest}>{children}</NextLink>;
};
