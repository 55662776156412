import { Analytics } from 'context/analytics';
import { Invoice, Listing, ListingImageType } from 'types';

import { fireTrackEvent } from './helpers';

export const logInitiatedPayment = (analytics: Analytics, listing: Listing, invoice: Invoice) => {
  const model = listing.model;

  const payload = {
    listingId: listing.id,
    modelId: model.id,
    modelName: model.displayName,
    referenceNumber: model.referenceNumber,
    condition: listing.condition,
    manufactureYear: listing.manufactureYear,
    frontImageUrl: listing.images.find(i => i.type === ListingImageType.FRONT)?.image.optimizedUrl,
    brandName: model.brand.displayName,
    paymentMethod: invoice.paymentType,
    listingPrice: (listing.priceCents / 100.0).toFixed(2),
    addonPrice: ((invoice?.addonCostCents || 0) / 100.0).toFixed(2),
    addons: invoice.addons?.map(a => a.key) ?? [],
    shippingCost: ((invoice?.shippingCostCents || 0) / 100.0).toFixed(2),
    paymentProcessingCost: ((invoice?.paymentProcessingFeeCents || 0) / 100.0).toFixed(2),
    discountAmount: ((invoice?.discountAmountCents || 0) / 100.0).toFixed(2),
    discountCode: invoice.discountCode,
    taxCost: ((invoice?.taxCostCents || 0) / 100.0).toFixed(2),
    totalCost: ((invoice?.totalPriceCents || 0) / 100.0).toFixed(2),
    creditsApplied: (invoice.creditsApplied / 100.0).toFixed(2),
    paymentDue: (invoice.paymentAmountCents / 100.0).toFixed(2),
    offersAllowed: listing.activePricingModel === 'AUCTION' ? false : listing.offersAllowed,
    activePricingModel: listing.activePricingModel,
    currency: 'USD'
  };

  fireTrackEvent(analytics, 'Initiated Payment', payload);
};
