import { Analytics } from 'context/analytics';
import { BuyerProfile, User } from 'types';

import { fireTrackEvent } from './helpers';

export const logCopiedReferralCode = (analytics: Analytics, user: User, buyerProfile: BuyerProfile) => {
  const payload = {
    userId: buyerProfile.userId || user.id,
    userName: user.fullName,
    userEmail: user.email,
    referralCode: buyerProfile.referralCode,
    referralDiscountPrice: (buyerProfile.referralDiscountCents / 100.0).toFixed(2),
    referralRewardPrice: (buyerProfile.referralRewardCents / 100.0).toFixed(2),
    currency: 'USD'
  };

  fireTrackEvent(analytics, 'Copied Referral Code', payload);
};
