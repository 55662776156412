import TagManager from 'react-gtm-module';

import { Analytics } from 'context/analytics';
import { Listing, ListingCondition, ListingImageType, PricingModels, User } from 'types';

import { fireTrackEvent, getAttributionToken } from './helpers';

type Payload = {
  listingId: number;
  modelId: number;
  modelName: string;
  referenceNumber: string;
  condition: ListingCondition;
  manufactureYear?: number | undefined;
  frontImageUrl?: string | undefined;
  brandName: string;
  price: string;
  currency: string;
  attributionToken?: string;
  offersAllowed?: boolean;
  activePricingModel: PricingModels;
};

export const logBeginCheckout = (analytics: Analytics, listing: Listing) => {
  const model = listing.model;
  const attributionToken = getAttributionToken();

  const payload: Payload = {
    listingId: listing.id,
    modelId: model.id,
    modelName: model.displayName,
    referenceNumber: model.referenceNumber,
    condition: listing.condition,
    manufactureYear: listing.manufactureYear,
    frontImageUrl: listing.images.find(i => i.type === ListingImageType.FRONT)?.image.optimizedUrl,
    brandName: model.brand.displayName,
    price: (listing.priceCents / 100.0).toFixed(2),
    offersAllowed: listing.activePricingModel === 'AUCTION' ? false : listing.offersAllowed,
    activePricingModel: listing.activePricingModel,
    currency: 'USD'
  };

  if (attributionToken) {
    payload.attributionToken = attributionToken;
  }

  fireTrackEvent(analytics, 'Begin Checkout', payload);
};

export const logGTMBeginCheckout = (listing: Listing, user?: User) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'begin_checkout',
      ecommerce: {
        items: [
          {
            currency: 'USD',
            item_id: listing.id,
            item_name: listing.model.displayName,
            item_brand: listing.model.brand.displayName,
            price: listing.priceCents / 100.0,
            activePricingModel: listing.activePricingModel,
            quantity: 1
          }
        ]
      },
      ...(user && {
        email: user.email,
        userId: user.id
      })
    }
  });
};
