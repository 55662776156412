import { Listing } from 'types';

export const getTitleFromListing = (listing: Listing, includeReferenceNumber = true) => {
  const titleParts = [
    listing?.manufactureYear,
    listing?.model?.brand?.displayName,
    listing?.model?.displayName,
    includeReferenceNumber ? listing?.model?.referenceNumber : null
  ].filter(t => t);
  const title = titleParts.join(' ');

  return title;
};
