import TagManager from 'react-gtm-module';

import { Analytics } from 'context/analytics';
import { Listing, ListingImageType, User } from 'types';

import { fireTrackEvent, getAttributionToken } from './helpers';
import { ListingPayload } from './types';

export const logSubmittedOffer = (analytics: Analytics, listing: Listing, offerPriceCents: number) => {
  const model = listing.model;
  const attributionToken = getAttributionToken();

  const payload: ListingPayload = {
    listingId: listing.id,
    modelId: model.id,
    modelName: model.displayName,
    referenceNumber: model.referenceNumber,
    condition: listing.condition,
    manufactureYear: listing.manufactureYear,
    frontImageUrl: listing.images.find(i => i.type === ListingImageType.FRONT)?.image.optimizedUrl,
    brandName: model.brand.displayName,
    listedPrice: (listing.priceCents / 100.0).toFixed(2),
    offerPrice: (offerPriceCents / 100.0).toFixed(2),
    offersAllowed: listing.activePricingModel === 'AUCTION' ? false : listing.offersAllowed,
    activePricingModel: listing.activePricingModel,
    currency: 'USD'
  };

  if (attributionToken) {
    payload.attributionToken = attributionToken;
  }

  fireTrackEvent(analytics, 'Submitted Offer', payload);
};

export const logGTMSubmittedOffer = (listing: Listing, offerPriceCents: number, user?: User) => {
  const model = listing.model;

  TagManager.dataLayer({
    dataLayer: {
      event: 'submitted_offer',
      listingId: listing.id,
      modelId: model.id,
      modelName: model.displayName,
      referenceNumber: model.referenceNumber,
      condition: listing.condition,
      manufactureYear: listing.manufactureYear,
      brandName: model.brand.displayName,
      listedPrice: (listing.priceCents / 100.0).toFixed(2),
      offerPrice: (offerPriceCents / 100.0).toFixed(2),
      activePricingModel: listing.activePricingModel,
      currency: 'USD',
      ...(user && {
        email: user.email,
        userId: user.id
      })
    }
  });
};
