import TagManager from 'react-gtm-module';

import { Analytics } from 'context/analytics';
import { Listing, User } from 'types';

import { fireTrackEvent } from './helpers';

export const logShippingInfoSelected = (analytics: Analytics, listing: Listing) => {
  const payload = {
    listingId: listing.id,
    offersAllowed: listing.activePricingModel === 'AUCTION' ? false : listing.offersAllowed,
    activePricingModel: listing.activePricingModel
  };

  fireTrackEvent(analytics, 'Shipping Info Selected', payload);
};

export const logGTMShippingInfoSelected = (listing: Listing, user?: User) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_shipping_info',
      ecommerce: {
        items: [
          {
            currency: 'USD',
            item_id: listing.id,
            item_name: listing.model.displayName,
            item_brand: listing.model.brand.displayName,
            price: listing.priceCents / 100.0,
            activePricingModel: listing.activePricingModel,
            quantity: 1
          }
        ]
      },
      ...(user && {
        email: user.email,
        userId: user.id
      })
    }
  });
};
