import { NextFont } from 'next/dist/compiled/@next/font';
import localFont from 'next/font/local';

const bradfordRegularItalic = localFont({
  src: '../public/fonts/bradford/BradfordLLWeb-Italic.woff2',
  display: 'swap'
});

const bradfordMediumItalic = localFont({
  src: '../public/fonts/bradford/BradfordLLWeb-MediumItalic.woff2',
  display: 'swap'
});

const bradfordMedium = localFont({ src: '../public/fonts/bradford/BradfordLLWeb-Medium.woff2', display: 'swap' });

const bradfordRegular = localFont({ src: '../public/fonts/bradford/BradfordLLWeb-Regular.woff2', display: 'swap' });

export const bradford = {
  regularItalic: bradfordRegularItalic,
  mediumItalic: bradfordMediumItalic,
  medium: bradfordMedium,
  regular: bradfordRegular
} satisfies Record<string, NextFont>;
