import { ReactNode } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const toaster = {
  success: (el: ReactNode) => {
    toast.success(<div className='riforma-regular fs-14px'>{el}</div>);
  },
  error: (el: ReactNode) => {
    toast.error(<div className='riforma-regular fs-14px'>{el}</div>);
  },
  info: (el: ReactNode) => {
    toast(<div className='riforma-regular fs-14px'>{el}</div>);
  }
};

const ToastProvider = () => (
  <Toaster
    toastOptions={{
      duration: 4000
    }}
  />
);

export { toaster, ToastProvider };
