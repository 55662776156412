import { bradford } from './bradford';
import { riforma } from './riforma';

export const FontGlobals = () => (
  <style jsx global>{`
    html,
    body {
      font-family: ${riforma.medium.style.fontFamily};
    }

    .riforma-light {
      font-family: ${riforma.light.style.fontFamily};
      font-weight: 300;
    }

    .riforma-regular {
      font-family: ${riforma.regular.style.fontFamily};
      font-weight: 400;
    }

    .riforma-medium {
      font-family: ${riforma.medium.style.fontFamily};
      font-weight: 500;
    }

    .bradford-regular-italic {
      font-family: ${bradford.regularItalic.style.fontFamily};
      font-weight: 400;
    }

    .bradford-medium-italic {
      font-family: ${bradford.mediumItalic.style.fontFamily};
      font-weight: 500;
    }

    .bradford-medium {
      font-family: ${bradford.medium.style.fontFamily};
      font-weight: 500;
    }

    .bradford-regular {
      font-family: ${bradford.regular.style.fontFamily};
      font-weight: 400;
    }
  `}</style>
);
