import { useInitAnalytics } from './useInitAnalytics';
import { useInitEquinox } from './useInitEquinox';
import { useInitIntercom } from './useInitIntercom';
import { useInitTagManager } from './useInitTagManager';
import { useRiskified } from './useRiskified';

export const useInit = () => {
  useInitAnalytics();
  useInitTagManager();
  useInitEquinox();
  useInitIntercom();
  useRiskified();
};
