import { MdOutlineGroup } from '@react-icons/all-files/md/MdOutlineGroup';
import { MdOutlineMonetizationOn } from '@react-icons/all-files/md/MdOutlineMonetizationOn';
import { MdOutlineWatch } from '@react-icons/all-files/md/MdOutlineWatch';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import { Slideout, SlideoutCommonProps } from 'components/design-system/Slideout';

export const EquinoxModalTestIds = {
  container: 'equinox-modal-container'
};

export type EquinoxModalProps = SlideoutCommonProps;

export const EquinoxModal = (props: EquinoxModalProps) => (
  <Slideout
    data-testid={EquinoxModalTestIds.container}
    {...props}
    title='The exclusive watch destination for Equinox members'
  >
    <div className='h-100 d-flex flex-column justify-content-between align-items-center gap-5'>
      <div className='h-100 text-secondary riforma-regular fs-14px d-flex flex-column justify-content-center align-items-center gap-3'>
        <CloudinaryImage src='equinox-bezel-logo.png' alt='Equinox | Bezel Logo' width={250} height={20} />
        <div className='d-flex align-items-center justify-content-between mt-5'>
          <MdOutlineMonetizationOn size={30} className='me-4' />
          <div>
            <span className='text-success'>Get $250 in Bezel credits</span> when you create your account
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between'>
          <MdOutlineGroup size={30} className='me-4' />
          Designated Concierge team for Equinox members
        </div>
        <div className='d-flex align-items-center justify-content-between'>
          <MdOutlineWatch size={30} className='me-4' />
          Access to exclusive Equinox | Bezel curated inventory
        </div>
      </div>
      <div className='d-flex flex-column align-items-center w-100 gap-1 mb-6'>
        <Link href='/api/auth/signup' className='w-100 fs-14px py-3 rounded-2 text-center bg-primary text-light'>
          Create an account
        </Link>
        <Link href='/api/auth/login' className='text-primary fs-14px text-center d-block mt-3'>
          Already have an account? <span className='text-decoration-underline'>Log in</span>
        </Link>
      </div>
    </div>
  </Slideout>
);
