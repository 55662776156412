import { ONE_DAY, ONE_HOUR } from 'utils/date';

const getTimeRemaining = (date: string) => {
  const total = Date.parse(date) - Date.parse(new Date().toString());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / ONE_HOUR) % 24);
  const days = Math.floor(total / ONE_DAY);

  return {
    total,
    days,
    hours,
    minutes,
    seconds
  };
};

export const getFormattedTimeRemaining = (date: string) => {
  const { days, hours, minutes } = getTimeRemaining(date);

  if (days > 0) {
    return `${days} days left`;
  } else if (hours > 0) {
    return `${hours} hours left`;
  } else if (minutes > 0) {
    return `${minutes} mins left`;
  }

  return null;
};
