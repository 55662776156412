export const SEARCH_QUERIES = {
  dialColors: 'dialColors',
  caseMaterials: 'caseMaterials',
  priceLowerBound: 'priceLowerBound',
  priceUpperBound: 'priceUpperBound',
  caseSizeLowerBound: 'caseSizeLowerBound',
  caseSizeUpperBound: 'caseSizeUpperBound',
  brands: 'brands',
  series: 'series',
  sort: 'sort',
  type: 'type',
  available: 'available',
  query: 'query',
  page: 'page'
};

export const SEARCH_API_QUERIES_MAP = {
  [SEARCH_QUERIES.series]: 'seriesId[]',
  [SEARCH_QUERIES.brands]: 'brandId[]',
  [SEARCH_QUERIES.dialColors]: 'dialColorIds[]',
  [SEARCH_QUERIES.caseMaterials]: 'caseMaterialIds[]',
  [SEARCH_QUERIES.type]: 'type[]',
  [SEARCH_QUERIES.sort]: 'sort[]'
};
