import { FC } from 'react';
import { IoIosArrowRoundForward } from '@react-icons/all-files/io/IoIosArrowRoundForward';

type SubmitButtonWithArrow = {
  text: string;
};

export const SubmitButtonWithArrow: FC<SubmitButtonWithArrow> = ({ text }) => (
  <div className='d-flex justify-content-between align-items-center'>
    <span />
    <span>{text}</span> <IoIosArrowRoundForward size={24} />
  </div>
);
