import classNames from 'classnames';

import { EquinoxModal } from 'components/equinox/EquinoxModal';
import { useModal } from 'context/modal/ModalContext';
import styles from 'styles/TopNav.module.scss';

export const EquinoxRibbon = () => {
  const { openModal, closeModal } = useModal();

  const gotoEquinoxModal = () => openModal(<EquinoxModal closeModal={closeModal} />);

  return (
    <div
      className={classNames(
        'w-100 text-center py-3 text-light riforma-regular fs-14px top-nav-hover',
        styles.bannerStrip
      )}
      style={{ transition: '500ms' }}
      onClick={gotoEquinoxModal}
      role='button'
    >
      <span className='d-none d-sm-inline'>Equinox Members, create an account to unlock exclusive benefits!</span>
      <span className='d-inline d-sm-none'>Exclusive Equinox benefits!</span>
      <span className='text-light text-uppercase ms-4 opacity-60 d-none d-sm-inline'>Check it out</span>
    </div>
  );
};
