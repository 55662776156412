/* these values will be used as MAX width in queries */
export const BREAKPOINTS = {
  xs: 575,
  sm: 767,
  md: 991,
  lg: 1199,
  xlg: 1399
};

export const IMAGE_SIZES = [828, 1080, 1920, 3840];

export const COLORS = {
  primary: '#1a3a32',
  primaryLight: '#fefcf8',
  primaryLight40: 'rgba(255, 252, 249, 0.4)',
  primaryLight60: 'rgba(255, 252, 249, 0.6)',
  primaryLight70: 'rgba(255, 252, 249, 0.7)',
  primaryDark: '#1b1c1d',
  primaryDarkLight: '#282a2c',
  turquoise: '#DEFFF8',
  turquoise08: 'rgba(209, 255, 245, 0.08)',
  turquoise70: 'rgba(209, 255, 245, 0.6)',
  white: '#ffffff',
  black: '#000000',
  lightGreen: '#28C39F',
  success: 'rgba(7, 116, 91)',
  success40: 'rgba(7, 116, 91, 0.40)',
  darkGreen: 'rgba(7, 116, 91, 0.07)',
  light1: '#F4F2F0',
  pink: 'rgba(248, 190, 190, 1.0)',
  pink08: 'rgba(248, 190, 190, 0.08)',
  pink70: 'rgba(248, 190, 190, 0.6)',
  darkRed: '#b41616',
  darkRed2: '#710E0E',
  yellow: '#cfb427',
  green: '#11a139',
  lightGrey: 'rgba(26, 58, 50, 0.01)',
  grey: 'rgba(26, 58, 50, 0.12)',
  grey20: 'rgba(26, 58, 50, 0.20)'
};

export const THEMES = {
  default: 'default',
  dark: 'dark'
};
