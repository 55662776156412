import { useEffect } from 'react';
import { useRouter } from 'next/router';
import omit from 'lodash/omit';

import { EquinoxEnrollmentCompletionModal } from 'components/equinox/EquinoxEnrollmentCompletionModal';
import { EQUINOX_UUID } from 'consts/storageKeys';
import { useBezelUser } from 'context/bezelUser';
import { useModal } from 'context/modal/ModalContext';
import { getFromStorage, removeFromStorage, setToStorage } from 'lib/storageHelpers';

export const useInitEquinox = () => {
  const { isReady, query, pathname, replace } = useRouter();
  const { user } = useBezelUser();
  const { openModal, closeModal } = useModal();
  const equinoxUuid = getFromStorage(EQUINOX_UUID);

  useEffect(() => {
    if (typeof query.equinox_uuid === 'string' && isReady) {
      setToStorage(EQUINOX_UUID, query.equinox_uuid);
      replace({ pathname, query: omit(query, ['equinox_uuid']) }, undefined, { shallow: true });
    }
  }, [isReady, pathname, query, replace]);

  useEffect(() => {
    if (user && user.givenName && equinoxUuid) {
      if (user.enrollments?.find(enrollment => enrollment.program.key === 'EQUINOX')) {
        removeFromStorage(EQUINOX_UUID);
      } else if (equinoxUuid) {
        openModal(<EquinoxEnrollmentCompletionModal closeModal={closeModal} />);
      }
    }
  }, [closeModal, equinoxUuid, openModal, user]);
};
