import orderBy from 'lodash/orderBy';

export const getSortedList = (list: object, key: string | number, asc = false) => {
  if (key) {
    return orderBy(list, [key, 'updated'], [asc ? 'asc' : 'desc', 'desc']);
  } else if (key === 'updated') {
    return orderBy(list, [key], [asc ? 'asc' : 'desc']);
  }

  return orderBy(list, ['updated'], ['desc']);
};
