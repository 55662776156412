export const brandItems = [
  { brandKey: 'rolex', label: 'Rolex' },
  { brandKey: 'audemars-piguet', label: 'Audemars Piguet' },
  { brandKey: 'cartier', label: 'Cartier' },
  { brandKey: 'patek-philippe', label: 'Patek Philippe' },
  { brandKey: 'vacheron-constantin', label: 'Vacheron Constantin' },
  { brandKey: 'a-lange-&-söhne', label: 'A. Lange & Söhne' },
  { brandKey: 'omega', label: 'Omega' },
  { brandKey: 'panerai', label: 'Panerai' },
  { brandKey: 'tudor', label: 'Tudor' },
  { brandKey: 'iwc', label: 'IWC' },
  { brandKey: 'breitling', label: 'Breitling' }
];

export const priceItems = [
  { label: 'Under $5K', priceKey: 'under-5000' },
  { label: 'Under $10K', priceKey: 'under-10000' },
  { label: '$10K-20K', priceKey: '10000-20000' },
  { label: '$20K-50K', priceKey: '20000-50000' },
  { label: '$50K+', priceKey: 'over-50000' }
];

export const accountItems = [
  { label: 'Orders', href: '/orders' },
  { label: 'Offers', href: '/offers' },
  { label: 'My wants', href: '/wants' },
  { label: 'Auctions', href: '/your-auctions' },
  { label: 'Account', href: '/account/profile' }
];

export enum Title {
  Brands = 'Brands',
  Prices = 'Prices',
  FeaturedCollections = 'Featured Collections',
  Account = 'Your Account'
}
