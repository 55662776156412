import { NextFont } from 'next/dist/compiled/@next/font';
import localFont from 'next/font/local';

const riformaLight = localFont({ src: '../public/fonts/riforma/RiformaLLWeb-Light.woff2', display: 'swap' });

const riformaRegular = localFont({ src: '../public/fonts/riforma/RiformaLLWeb-Regular.woff2', display: 'swap' });

const riformaMedium = localFont({ src: '../public/fonts/riforma/RiformaLLWeb-Medium.woff2', display: 'swap' });

export const riforma: Record<string, NextFont> = {
  light: riformaLight,
  regular: riformaRegular,
  medium: riformaMedium
} satisfies Record<string, NextFont>;
