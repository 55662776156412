import { ExploreQueryParams } from 'components/explore/helpers/transformFilters';
import { QueryParams } from 'types';

import { constructSearchUrl } from './api/filters';
import { convertSearchQueries, extendUrlByQueries, getEncodedString } from './url_helpers';

export const apiUrls = {
  catalogBrands: ({ brandName }: { brandName: string }) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/catalog/brands`;

    return extendUrlByQueries(baseUrl, { 'brandKey[]': getEncodedString(brandName) });
  },
  catalogSeries: ({ seriesName }: { seriesName: string }) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/catalog/series/search`;

    return extendUrlByQueries(baseUrl, { q: getEncodedString(seriesName) });
  },
  catalogModels: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/catalog/models`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  catalogBrandsById: (brandId: number | string) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/catalog/brands/${brandId}`;

    return extendUrlByQueries(baseUrl);
  },
  catalogSearch: (searchQueries: QueryParams) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/catalog/search`;

    return extendUrlByQueries(baseUrl, convertSearchQueries(searchQueries));
  },
  search: (searchQueries: QueryParams) => {
    const baseUrl = '/api/search/search';

    return extendUrlByQueries(baseUrl, convertSearchQueries(searchQueries));
  },
  batchListingsStats: ({ modelIds }: { modelIds: number[] }) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/api/batchListings/stats`;

    return extendUrlByQueries(baseUrl, { modelIds });
  },
  listingsStats: () => `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/api/listings/stats`,
  auctionsStats: () => `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/api/listings/stats/auctions`,
  listingStatsCount: () => `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/api/listings/stats/count`,
  listingStatsCountAuctions: () =>
    extendUrlByQueries(`${process.env.NEXT_PUBLIC_SITE_BASE_URL}/api/listings/stats`, {
      activePricingModel: 'AUCTION'
    }),
  filterSeries: (brandId: number | string) =>
    `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/api/filters/series?brand=${brandId}`,
  collectionDetail: (collectionId: number | string, queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/content/collections/${collectionId}`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  homeFeeds: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/content/home-feeds`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  homeFeedsLive: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/content/home-feeds/live`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  listing: (
    listingId: string | number,
    queryParams = {},
    params: { includeBaseUrl: boolean } = { includeBaseUrl: false }
  ) => {
    let baseUrl = `/marketplace/listings/${listingId}`;

    if (params.includeBaseUrl) {
      baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/listings/${listingId}`;
    }

    return extendUrlByQueries(baseUrl, queryParams);
  },
  similarListings: (modelId: number) =>
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/listings?statuses=PUBLISHED&models=${modelId}`,
  explore: ({ query, page, queryParams }: { query: string; page: number; queryParams: ExploreQueryParams }) => {
    const baseSearchUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/catalog/search?type[]=MODEL&query=${query}&page=${page}`;

    return constructSearchUrl(query, queryParams, baseSearchUrl);
  },
  batchListing: (queryParams: QueryParams) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/api/batchListings/stats`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  listings: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/listings`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  listingIds: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/listings/ids`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  drops: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/drops`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  sellerProfiles: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/seller-profiles`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  sellerMetaData: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/seller-site-metadata`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  declineOffer: (offerId: number) => `/api/marketplace/offers/${offerId}/decline`,
  acceptOffer: (offerId: number) => `/api/marketplace/offers/${offerId}/accept`,
  counterOffer: (offerId: number) => `/api/marketplace/offers/${offerId}/counter`,
  createOffer: () => '/api/marketplace/offers/create',
  getOffer: (offerId: number) => `/api/marketplace/offers/${offerId}/offer`,
  offers: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/offers`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  orders: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/orders`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  articles: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/content/articles`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  getList: (id: number, queryParams: QueryParams = {}) => extendUrlByQueries(`/api/lists/${id}`, queryParams),
  lookupSeller: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/seller-profiles/lookup`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  sellerLevels: (
    queryParams: QueryParams = {},
    params: { includeBaseUrl: boolean; serverApi?: boolean } = { includeBaseUrl: false }
  ) => {
    let baseUrl = '';

    if (params.serverApi) {
      return extendUrlByQueries('/api/marketplace/sellerLevels', queryParams);
    }

    if (params.includeBaseUrl) {
      baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/seller-levels`;
    } else {
      baseUrl = '/marketplace/seller-levels';
    }

    return extendUrlByQueries(baseUrl, queryParams);
  },
  maxBids: (
    queryParams: QueryParams = {},
    params: { includeBaseUrl: boolean; serverApi?: boolean } = { includeBaseUrl: false }
  ) => {
    let baseUrl = '';

    if (params.serverApi) {
      return extendUrlByQueries(`/api/max-bids`, queryParams);
    }

    if (params.includeBaseUrl) {
      baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/proxy-bids`;
    } else {
      baseUrl = '/marketplace/proxy-bids';
    }

    return extendUrlByQueries(baseUrl, queryParams);
  },
  brandModels: (brandId: number) => `${process.env.NEXT_PUBLIC_API_BASE_URL}/catalog/models?brands=${brandId}`,
  modelDetail: (id: number, queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/catalog/models/${id}`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  models: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/catalog/models`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  modelIds: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/catalog/models/ids`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  brands: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/catalog/brands`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  series: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/catalog/series`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  collections: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/content/collections`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  appleReviews: ({ sortBy, page, format }: { sortBy: string; page: number; format: string }) =>
    `${process.env.APPLE_PUBLIC_RSS_FEED_BASE_URL}/customerreviews/id=${process.env.APPLE_APP_ID}/sortBy=${sortBy}/page=${page}/${format}`,
  trustPilotReviews: ({
    stars,
    language,
    perPage,
    orderBy
  }: {
    stars: number;
    language: string;
    perPage: number;
    orderBy: string;
  }) =>
    `${process.env.TRUSTPILOT_API_BASE_URL}/business-units/${process.env.TRUSTPILOT_BUSINESS_ID}/reviews?stars=${stars}&language=${language}&perPage=${perPage}&orderBy=${orderBy}`,
  addToWants: (modelId: number) => `/api/marketplace/wishlist/${modelId}`,
  selectedSale: () =>
    'https://data.plasmic.app/api/v1/cms/databases/2eVVSEaFEs4UqS4ercFPf2/tables/saleRibbonData/query',
  proxyBid: (userId: number, listingId: number, params: { serverApi: boolean } = { serverApi: false }) => {
    if (params.serverApi) {
      return `/api/auctions/proxy-bid/${userId}/${listingId}`;
    }

    return extendUrlByQueries(`${process.env.NEXT_PUBLIC_API_BASE_URL}/marketplace/proxy-bids`, {
      'buyerProfile[]': userId,
      'listing[]': listingId,
      'expand[]': ['listing']
    });
  },
  contentCategories: (queryParams: QueryParams = {}) => {
    const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/content/categories`;

    return extendUrlByQueries(baseUrl, queryParams);
  },
  category: (slug: string[]) => {
    let path = '';

    slug.forEach(elem => (path += `/${elem}`));

    return extendUrlByQueries(`${process.env.NEXT_PUBLIC_API_BASE_URL}/content/categories`, {
      'path[]': encodeURIComponent(path)
    });
  },
  lookupCategory: (queryParams: QueryParams = {}) => extendUrlByQueries('/api/categories/lookup', queryParams)
};
