import { FaCheck } from '@react-icons/all-files/fa6/FaCheck';

export const SuccessIcon = () => (
  <div
    className='d-flex align-items-center justify-content-center border-radius-46 bg-green2 text-green mb-4'
    style={{ height: 32, width: 32 }}
  >
    <FaCheck size={18} />
  </div>
);
