import { FC, memo } from 'react';
import { Spinner as SpinnerComponent, SpinnerProps as SpinnerComponentProps } from 'react-bootstrap';

type SpinnerProps = {
  regularSize?: boolean;
} & Partial<SpinnerComponentProps>;

export const Spinner: FC<SpinnerProps> = memo(
  ({ as = 'span', animation = 'border', size = 'sm', role = 'status', regularSize = false, ...rest }) => (
    <SpinnerComponent as={as} animation={animation} size={regularSize ? undefined : size} role={role} {...rest} />
  )
);
