import classNames from 'classnames';

import { intercomShowNewMessage } from 'lib/intercom';
import styles from 'styles/TopNav.module.scss';

export const SellerProfileRibbon = () => (
  <div
    className={classNames('w-100 text-center py-3 text-primary riforma-regular fs-14px bg-light', styles.sellerStrip)}
    onClick={intercomShowNewMessage}
    role='button'
  >
    This is a seller BETA. Any feedback?
    <span className='text-secondary text-uppercase ms-4 opacity-80 d-none d-sm-inline'>LET US KNOW</span>
  </div>
);
