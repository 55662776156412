import classNames from 'classnames';

import { Link } from 'components/common/Link';
import styles from 'styles/TopNav.module.scss';

export const DefaultRibbon = () => (
  <Link
    href='/explore'
    className={classNames(
      'w-100 d-block text-center py-3 text-light riforma-regular fs-14px top-nav-hover',
      styles.bannerStrip
    )}
    style={{ transition: '500ms' }}
  >
    We authenticate everything in-house
    <span className='text-light text-uppercase ms-4 opacity-60 d-none d-sm-inline'>Shop Now</span>
  </Link>
);
