import { SellerLevel, SellerProfile } from 'types';

export const getListingBreakdownPrice = (
  listing: {
    priceCents: number;
    overrideCommissionRateBips?: number;
    overrideSellerFeeCents?: number;
    overrideMinTotalFeeCents?: number;
  },
  sellerProfile?: SellerProfile | null,
  sellerLevels?: SellerLevel[]
) => {
  const currentPriceCents = listing?.priceCents;
  const sellerLevel = sellerLevels?.find?.(sellerLvl => sellerLvl.level === sellerProfile?.sellerLevel);
  const commissionBips =
    listing.overrideCommissionRateBips ??
    sellerProfile?.overrideCommissionRateBips ??
    sellerLevel?.commissionRateBips ??
    0;
  const sellerFeeCents =
    listing.overrideSellerFeeCents ?? sellerProfile?.overrideSellerFeeCents ?? sellerLevel?.sellerFeeCents ?? 0;
  const minimumTotalFee =
    listing.overrideMinTotalFeeCents ?? sellerProfile?.overrideMinTotalFeeCents ?? sellerLevel?.minTotalFeeCents ?? 0;
  const commissionCentsWithSellerFee =
    Math.round((currentPriceCents * commissionBips) / 10000 / 100) * 100 + sellerFeeCents;

  const commissionCents = Math.max(minimumTotalFee, commissionCentsWithSellerFee);

  const earnings = Math.max(0, currentPriceCents - commissionCents);

  return { commissionCents, commissionBips, earnings, sellerFeeCents, minimumTotalFee };
};
