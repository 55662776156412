const regex = /\|(.*?)\|/;

// this function adds special styling to text surrounded by *
// example 'Equinox Members *Only*' should result in <>Equinox Members <span className='bradford-regular-italic'>Only</span></>
export const getCustomMarkupFormattedText = (initialValue: string) => {
  const matched = regex.exec(initialValue.replace(/\*/g, '|'));

  if (!matched) {
    // no *any text* found
    return initialValue;
  }

  const { input, index } = matched;
  const markedValue = matched[1];
  const lengthToReplace = markedValue.length + 2; // +2 means to include both markedUpSigns

  return (
    <>
      {input.slice(0, index)}
      <span className='bradford-regular-italic' key={markedValue}>
        {markedValue}
      </span>
      {input.slice(index + lengthToReplace)}
    </>
  );
};
