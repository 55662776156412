import { useEffect } from 'react';

import { checkoutSessionId } from 'consts/api';

const store_domain = 'getbezel.com';
const session_id = checkoutSessionId;

// copied from https://apiref.riskified.com/#beacon
export const useRiskified = () =>
  useEffect(() => {
    const url =
      ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'beacon.riskified.com?shop=' +
      store_domain +
      '&sid=' +
      session_id;

    const s = document.createElement('script');

    s.type = 'text/javascript';
    s.async = true;
    s.src = url;
    const x = document.getElementsByTagName('script')[0];

    x.parentNode?.insertBefore(s, x);
  }, []);
