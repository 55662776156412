import { FC } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { useBezelUser } from 'context/bezelUser';
import { useViewport } from 'context/viewport';
import styles from 'styles/TopNav.module.scss';
import { SelectedSaleData } from 'types';

type SelectedSaleRibbonProps = {
  data: SelectedSaleData;
};

export const SelectedSaleRibbon: FC<SelectedSaleRibbonProps> = ({ data }) => {
  const router = useRouter();
  const { user } = useBezelUser();

  let saleData: SelectedSaleData['rows'][number]['data'] | undefined;

  if (user && user.enrollments.length > 0 && user.enrollments[0].program.key === 'EQUINOX') {
    saleData = data.rows.find(row => row.identifier === 'Logged In Equinox')?.data;
  } else if (user) {
    saleData = data.rows.find(row => row.identifier === 'Logged In')?.data;
  } else {
    saleData = data.rows.find(row => row.identifier === 'Logged Out')?.data || data.rows[0].data;
  }

  const baseUrl = process.env.NEXT_PUBLIC_SITE_BASE_URL || '';
  const { isMobile } = useViewport();

  const goToCollection = () => {
    if (!saleData?.link) {
      return;
    }

    const path = saleData.link.startsWith(baseUrl) ? saleData.link.split(baseUrl)[1] : baseUrl;

    router.push(path);
  };

  return (
    <div
      className={classNames(
        'w-100 text-center py-3 text-light riforma-regular fs-14px top-nav-hover',
        styles.bannerStrip
      )}
      style={{ transition: '500ms' }}
      onClick={goToCollection}
      {...(saleData?.link ? { role: 'button' } : {})}
    >
      <span className='d-inline'>{isMobile ? saleData?.mobileTitle : saleData?.desktopTitle}</span>
      {isMobile && saleData?.mobileCtaLabel && (
        <span className='text-light text-uppercase ms-4 opacity-60'>{saleData.mobileCtaLabel}</span>
      )}
      {!isMobile && saleData?.desktopCtaLabel && (
        <span className='text-light text-uppercase ms-4 opacity-60'>{saleData.desktopCtaLabel}</span>
      )}
    </div>
  );
};
