import TagManager from 'react-gtm-module';

import { Analytics } from 'context/analytics';
import { Invoice, Listing, Order, PaymentMethod, User } from 'types';

import { fireTrackEvent } from './helpers';

export const logPurchase = (
  analytics: Analytics,
  listing: Listing,
  order: Order,
  selectedPaymentMethod: PaymentMethod | null
) => {
  const payload = {
    listingId: listing.id,
    modelId: listing.model.id,
    modelName: listing.model.displayName,
    referenceNumber: listing.model.referenceNumber,
    brandName: listing.model?.brand?.displayName,
    orderId: order.id,
    paymentType: selectedPaymentMethod,
    price: (listing.priceCents / 100.0).toFixed(2),
    offersAllowed: listing.activePricingModel === 'AUCTION' ? false : listing.offersAllowed,
    activePricingModel: listing.activePricingModel,
    currency: 'USD'
  };

  fireTrackEvent(analytics, 'Purchase', payload);
};

export const logGTMPurchase = (
  listing: Listing,
  order: Order,
  invoice: Invoice,
  selectedPaymentMethod: PaymentMethod | null,
  user?: User
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'purchase',
      phone_number: invoice?.shippingAddress?.phoneNumber,
      ecommerce: {
        transaction_id: `order_${order.id}`,
        currency: 'USD',
        value: (invoice?.totalPriceCents || 0) / 100.0,
        shipping: 0,
        tax: (invoice?.taxCostCents || 0) / 100.0,
        payment_type: selectedPaymentMethod?.type,
        items: [
          {
            currency: 'USD',
            item_id: listing.id,
            item_name: listing.model.displayName,
            item_brand: listing.model.brand.displayName,
            price: listing.priceCents / 100.0,
            payment_type: selectedPaymentMethod?.type,
            activePricingModel: listing.activePricingModel,
            quantity: 1
          }
        ]
      },
      ...(user && {
        email: user.email,
        userId: user.id
      })
    }
  });
};
