import { v4 } from 'uuid';

export const API_URLS = {
  offers: '/api/marketplace/offers'
};

export const checkoutSessionId = '2023359-' + v4();

export const NotFoundRedirect = {
  redirect: {
    destination: '/404',
    permanent: true
  }
};
