export const isIntercomDefined = () => !!window?.Intercom;
export const intercomShutdown = () => window?.Intercom?.('shutdown');
export const intercomShowNewMessage = () => window?.Intercom?.('showNewMessage');
export const intercomShowLauncher = () =>
  window?.Intercom?.('update', {
    hide_default_launcher: false
  });
export const intercomHideLauncher = () => {
  window?.Intercom?.('update', {
    hide_default_launcher: true
  });
};
