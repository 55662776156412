export const formatPrice = (priceCents: number) => {
  const numberFormat = (value: number) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    })
      .format(value)
      .replace(/\D00$/, '');

  return numberFormat(priceCents / 100);
};
