import { HiArrowNarrowRight } from '@react-icons/all-files/hi/HiArrowNarrowRight';

import { Link } from 'components/common/Link';
import { useTheme } from 'context/theme';
import styles from 'styles/TopNav.module.scss';

export interface ShopAllButtonProps {
  href: string;
}

const shopAllTextStyles = {
  marginRight: '0.75rem'
};

const linkStyles = {
  borderBottom: '1px solid rgba(26, 58, 50, 0.2)',
  paddingBottom: '0.5rem'
};

export const ShopAllButton: React.FC<ShopAllButtonProps> = ({ href }) => {
  const { isDark } = useTheme();

  return (
    <li className={isDark ? styles.shopAllDark : styles.shopAll}>
      <Link className='cursor-pointer d-inline-flex align-items-center' style={linkStyles} href={href}>
        <div style={shopAllTextStyles}>Shop all</div>
        <HiArrowNarrowRight size='1.2em' />
      </Link>
    </li>
  );
};
