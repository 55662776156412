import isNil from 'lodash/isNil';

export const convertToList = <T>(value: T) => {
  if (!value) {
    return [];
  }

  return Array.isArray(value) ? value : [value];
};

export const convertToNumber = (value?: string | number | string[] | null) => {
  if (isNil(value)) {
    return value;
  }

  return typeof value === 'number' ? value : +value;
};

export const normalizeSrc = (src: string) => (src[0] === '/' ? src.slice(1) : src);

export const isWindowDefined = () => global.window && typeof window !== undefined && window;

export const isOnBezelDomain = () =>
  isWindowDefined() &&
  process.env.NEXT_PUBLIC_SITE_BASE_URL &&
  process.env.NEXT_PUBLIC_SITE_BASE_URL.includes(window.location.origin);

export const isDevDomain =
  isWindowDefined() && (window.location.origin.includes('sandbox') || window.location.origin.includes('staging'));

export const getHttpsLink = (link: string) => {
  if (link.includes('https://')) {
    return link;
  }

  return link.includes('http://') ? link.replace('http://', 'https://') : `https://${link}`;
};

export const isOnSafariBrowser = () =>
  isWindowDefined() && /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

export const isWindows = () => {
  const userAgent = window.navigator.userAgent;

  return userAgent.indexOf('Win') > -1;
};
