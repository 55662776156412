import mapValues from 'lodash/mapValues';
import toString from 'lodash/toString';

import { Analytics } from 'context/analytics';
import { getFromStorage } from 'lib/storageHelpers';

export const fireTrackEvent = (analytics: Analytics, key: string, payload: object) => {
  const stringPayload = stringifyPayload(payload);

  analytics.track(key, stringPayload);
};

export const stringifyPayload = (payload: object | undefined) => {
  if (!payload) {
    return {};
  }

  return mapValues(payload, val => toString(val));
};

export const getAttributionToken = () => getFromStorage('attribution');
