import { useEffect } from 'react';
import { useRouter } from 'next/router';
import omit from 'lodash/omit';

import { useAnalytics } from 'context/analytics';

const OPEN_SUPPORT = 'open_support';

export const useInitIntercom = () => {
  const analytics = useAnalytics();
  const router = useRouter();
  const showIntercom = router.query[OPEN_SUPPORT] === 'true';

  useEffect(() => {
    if (window.Intercom && analytics.ready) {
      window.Intercom('boot');
    }
  }, [analytics]);

  useEffect(() => {
    if (showIntercom && window.Intercom && analytics.ready) {
      const { pathname, query } = router;

      window.Intercom('showNewMessage');
      const newQuery = omit(query, [OPEN_SUPPORT]);

      router.replace({ pathname, query: newQuery }, undefined, { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showIntercom, analytics]);
};
