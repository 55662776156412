import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import noop from 'lodash/noop';

import { clientApiGet } from 'lib/api_helper';
import { pageRoutes } from 'lib/routes';
import { SellerSiteMetadata } from 'types';

interface BrandingContextProps {
  sellerSiteMetadata?: SellerSiteMetadata;
  isLoading: boolean;
  usePrimaryBranding: boolean;
  useSellerBranding: boolean;
  setSellerSiteMetadata: (data: SellerSiteMetadata) => void;
}

export const BrandingContext = createContext<BrandingContextProps>({
  isLoading: false,
  usePrimaryBranding: true,
  useSellerBranding: false,
  setSellerSiteMetadata: noop
});

interface BrandingContextProviderProps extends PropsWithChildren {
  sellerSiteMetadata?: SellerSiteMetadata[];
}

const isPathnameValidForSellerSite = (path: string) =>
  path === '/' || path.includes('sitemap') || (path.includes('watches') && path.includes('listing'));

export const BrandingContextProvider: React.FC<BrandingContextProviderProps> = ({
  sellerSiteMetadata: initialMetadata,
  children
}) => {
  const [sellerSiteMetadata, setSellerSiteMetadata] = useState<SellerSiteMetadata | undefined>(initialMetadata?.[0]);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const fetchSellerSiteMetadata = async () => {
      setIsLoading(true);

      const sellerSiteData = await clientApiGet<SellerSiteMetadata[] | null>('/api/sellerSiteData');

      if (sellerSiteData) {
        setSellerSiteMetadata(sellerSiteData[0]);
      }

      setIsLoading(false);
    };

    if (
      window &&
      process.env.NEXT_PUBLIC_SITE_BASE_URL &&
      !process.env.NEXT_PUBLIC_SITE_BASE_URL.includes(window.location.origin)
    ) {
      if (router.pathname !== pageRoutes.sellerNotFound && !isPathnameValidForSellerSite(router.pathname)) {
        router.replace(pageRoutes.sellerNotFound);
      } else if (!sellerSiteMetadata) {
        fetchSellerSiteMetadata().catch(error => {
          console.error(error);
        });
      }
    }
  }, [router, sellerSiteMetadata]);

  const providerValue = useMemo(
    () => ({
      sellerSiteMetadata,
      setSellerSiteMetadata,
      isLoading,
      usePrimaryBranding: !sellerSiteMetadata,
      useSellerBranding: !!sellerSiteMetadata
    }),
    [sellerSiteMetadata, isLoading]
  );

  return <BrandingContext.Provider value={providerValue}>{children}</BrandingContext.Provider>;
};

export const useBranding = () => useContext(BrandingContext);
