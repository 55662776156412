import { QueryParams, SellerSiteMetadata, Series } from 'types';

import {
  buildCollectionUrl,
  buildListingUrl,
  buildModelUrl,
  extendUrlByQueries,
  getEncodedString
} from './url_helpers';

export const pageRoutes = {
  brand: (brandKey: string, queryParams?: QueryParams) =>
    extendUrlByQueries(`/explore/${encodeURIComponent(brandKey)}`, queryParams),
  price: (priceHref: string, queryParams?: QueryParams) =>
    extendUrlByQueries(`/explore/${encodeURIComponent(priceHref)}`, queryParams),
  series: (series: Series, queryParams?: QueryParams) =>
    extendUrlByQueries(
      `/explore/${encodeURIComponent(series.brand.brandKey)}/${getEncodedString(series.slug.trim())}`,
      queryParams
    ),
  brandModel: ({
    brandDisplayName,
    modelDisplayName,
    referenceNumber,
    modelId
  }: {
    brandDisplayName: string;
    modelDisplayName: string;
    referenceNumber: string;
    modelId: number;
  }) => buildModelUrl(brandDisplayName, modelDisplayName, referenceNumber, modelId),
  fullListingUrl: ({
    brandDisplayName,
    modelDisplayName,
    referenceNumber,
    listingId
  }: {
    brandDisplayName: string;
    modelDisplayName: string;
    referenceNumber: string;
    listingId: number;
  }) => buildListingUrl(brandDisplayName, modelDisplayName, referenceNumber, listingId),
  explore: (searchQueries: QueryParams = {}) => extendUrlByQueries('/explore', searchQueries),
  models: (modelId: number) => `/models/${modelId}`,
  listings: (listingId: number) => `/listings/${listingId}`,
  listingCheckout: (id: number | string) => `/listings/${id}/checkout`,
  home: '/',
  listingCheckoutComplete: (
    id: number | string,
    reservationId: number | string,
    invoiceId: number | string,
    paymentMethod: string
  ) =>
    `/listings/${id}/complete_checkout?reservation_id=${reservationId}&invoice_id=${invoiceId}&payment_method=${paymentMethod}`,
  order: (id: number | string) => `/orders/${id}`,
  login: () => '/api/auth/login',
  collections: (collectionId: number) => `/collections/${collectionId}`,
  fullCollectionUrl: (collectionName: string, collectionId: number) => buildCollectionUrl(collectionName, collectionId),
  sellerInventory: '/sell/inventory',
  sellerDashboard: '/sell/dashboard',
  sellerOrders: '/sell/orders',
  sellerSite: '/sell/site',
  sellerSettings: '/sell/settings',
  sellerOffers: '/sell/offers',
  sellerWallet: '/sell/wallet',
  sellerCreateNewListing: '/sell/create-new-listing',
  sellerLanding: '/sell',
  sellerOnboarding: '/sell/onboarding',
  sellerEditListing: (id: number) => `/sell/inventory/${id}/edit`,
  sellerWebsite: (sellerSiteMetadata: SellerSiteMetadata) => `/sellers/${sellerSiteMetadata.slug}`,
  sellerNotFound: '/not-found',
  auctions: '/auctions',
  auctionsCountdown: '/auctions/countdown',
  paginatedSitemap: (pageNumber: number) => `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/sitemap/${pageNumber}.xml`,
  brandSitemap: `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/sitemap/brands.xml`,
  seriesSitemap: `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/sitemap/series.xml`,
  priceSitemap: `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/sitemap/prices.xml`,
  paginatedModelSitemap: (pageNumber: number | string) =>
    `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/sitemap/models/${pageNumber}.xml`,
  paginatedListingSitemap: (pageNumber: number | string) =>
    `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/sitemap/listings/${pageNumber}.xml`,
  paginatedCategorySitemap: (pageNumber: number | string) =>
    `${process.env.NEXT_PUBLIC_SITE_BASE_URL}/sitemap/categories/${pageNumber}.xml`,
  accountCredits: '/account/credits',
  accountAddresses: '/account/addresses',
  accountPaymentMethods: '/account/payment-methods',
  accountProfile: '/account/profile'
};
