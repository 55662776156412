import { FC, useCallback } from 'react';
import { Nav } from 'react-bootstrap';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import { useTheme } from 'context/theme';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/TopNav.module.scss';

import { NavbarDropdownMobile } from './NavbarDropdown';
import { NavlinkSell } from './NavlinkSell';
import { NavlinkShop } from './NavlinkShop';

type TopNavShopItemsProps = {
  isTabletOrMobile: boolean;
  setNavDropdownDesktopIsOpened: (val: boolean) => void;
  setNavDropdownDesktopWasForcedClosed: (val: boolean) => void;
  navDropdownDesktopIsOpened: boolean;
};

const linkCommonProps = {
  target: '_blank',
  rel: 'noreferrer'
};

export const TopNavShopItems: FC<TopNavShopItemsProps> = ({
  isTabletOrMobile,
  setNavDropdownDesktopIsOpened,
  setNavDropdownDesktopWasForcedClosed,
  navDropdownDesktopIsOpened
}) => {
  const router = useRouter();
  const { isDark } = useTheme();
  const pathname = usePathname();
  const classes = isDark ? styles.darkNav : `${styles.navItem} ${styles.brightOnHover}`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleNavbarDropdownOn = useCallback(
    throttle(
      () => {
        setNavDropdownDesktopIsOpened(true);
        setNavDropdownDesktopWasForcedClosed(false);
      },
      100,
      { leading: true, trailing: false }
    ),
    []
  );

  const toggleNavbarDropdownOff = debounce(() => setNavDropdownDesktopIsOpened(false), 200);
  const onClickShopButton = () => {
    router.push(pageRoutes.explore());
    toggleNavbarDropdownOff();
  };

  const isAuctionsPage = pathname?.includes('/auctions');

  return (
    <div
      id='nav-items'
      className='d-flex align-items-lg-center flex-column flex-lg-row mt-3 mt-lg-0 mb-3 mb-lg-0 mr-md-1 overflow-auto hide_scroll_bar'
    >
      <NavlinkShop
        classes={classes}
        onMouseMove={toggleNavbarDropdownOn}
        onMouseLeave={toggleNavbarDropdownOff}
        dropdownEnabled={!isTabletOrMobile}
        onClick={onClickShopButton}
        isActive={navDropdownDesktopIsOpened}
      />
      <NavbarDropdownMobile />
      {!isTabletOrMobile && (
        <>
          <Link legacyBehavior passHref href={pageRoutes.auctions}>
            <Nav.Link
              active={isAuctionsPage}
              className={classNames(classes, {
                'opacity-100': isAuctionsPage
              })}
            >
              Auctions
            </Nav.Link>
          </Link>
          <div className={styles.border}>
            <NavlinkSell className={classes} />
          </div>
          <div className={styles.border}>
            <Nav.Link active={false} className={classes} {...linkCommonProps} href='https://getbezel.com/journal'>
              Journal
            </Nav.Link>
          </div>
        </>
      )}
    </div>
  );
};
