import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';

import { User } from 'types';

import { clientApiGet } from '../lib/api_helper';

interface BezelUserContextProps {
  user?: User;
  isLoading: boolean;
  refresh: () => Promise<User | null>;
}
export const BezelUserContext = createContext<BezelUserContextProps>({
  isLoading: false,
  refresh: () => Promise.resolve(null)
});

type BezelUserContextProviderProps = {
  user?: User;
} & PropsWithChildren;

export const getBezelUser = async (id: string) => (await clientApiGet(`/api/users/${id}`)) as User;

export const BezelUserContextProvider: React.FC<BezelUserContextProviderProps> = ({ user: initialUser, children }) => {
  const { user, isLoading } = useUser();
  const [bezelUser, setBezelUser] = useState<User | undefined>(initialUser);
  const [bezelUserLoading, setBezelUserLoading] = useState(false);

  const refresh = useCallback(async () => {
    if (user && user.sub) {
      // fetch silently if bezelUser is already set
      if (!bezelUser) {
        setBezelUserLoading(true);
      }
      const response: User = await getBezelUser(user.sub);

      setBezelUser(prevValue => ({ ...prevValue, ...response }));
      setBezelUserLoading(false);

      return response;
    } else if (!user && !isLoading && bezelUser) {
      setBezelUser(undefined);
    }

    return null;
    // need to check whether is can be fixed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, bezelUser, setBezelUser, setBezelUserLoading]);

  useEffect(() => {
    refresh().catch(error => {
      console.error(error);
    });
    // need to check whether is can be fixed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const providerValue = useMemo(
    () => ({
      user: bezelUser,
      isLoading: bezelUserLoading,
      refresh
    }),
    [bezelUser, bezelUserLoading, refresh]
  );

  return <BezelUserContext.Provider value={providerValue}>{children}</BezelUserContext.Provider>;
};

export const useBezelUser = () => useContext(BezelUserContext);
