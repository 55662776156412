import { FC } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';

export const TopNavAuthDropdownItemTestIds = {
  container: 'top-nav-auth-dropdown-item-container'
};

export type TopNavAuthDropdownItemProps = {
  onClick: () => void;
  title: string;
  href?: string;
};

export const TopNavAuthDropdownItem: FC<TopNavAuthDropdownItemProps> = ({ onClick, title, href }) => (
  <NavDropdown.Item
    data-testid={TopNavAuthDropdownItemTestIds.container}
    href={href}
    onClick={onClick}
    className='fs-12px'
  >
    {title}
  </NavDropdown.Item>
);
