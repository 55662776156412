import { useEffect } from 'react';

import { IS_TIMEZONE_SENT } from 'consts/storageKeys';
import { clientApiPatch } from 'lib/api_helper';
import { getFromStorage, setToStorage } from 'lib/storageHelpers';

export const useSendUserTimezone = (userId?: string) => {
  useEffect(() => {
    if (!userId) {
      return;
    }

    const isTimezoneSent = getFromStorage(IS_TIMEZONE_SENT);

    if (isTimezoneSent) {
      return;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    clientApiPatch(`/api/users/identity/${userId}`, { timezone });
    setToStorage(IS_TIMEZONE_SENT, 'true');
  }, [userId]);
};
