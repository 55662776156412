import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useBezelUser } from 'context/bezelUser';
import { useGetTreatmentState } from 'context/featureToggle';
import { SALE_RIBBON } from 'context/featureToggle/consts';
import { useViewport } from 'context/viewport';
import { useEquinox } from 'hooks/useEquinox';

import { CustomRibbon } from './TopNavStripParts/CustomRibbon';
import { DefaultRibbon } from './TopNavStripParts/DefaultRibbon';
import { EquinoxRibbon } from './TopNavStripParts/EquinoxRibbon';
import { SelectedSaleRibbon } from './TopNavStripParts/SelectedSaleRibbon';
import { SellerProfileRibbon } from './TopNavStripParts/SellerProfileRibbon';
import { useGetSelectedSale } from './TopNavStripParts/useGetSelectedSale';

export const TopNavStrip = () => {
  const router = useRouter();
  const { isEnabled: selectedSaleRibbonEnabled, isLoading: selectedSaleRibbonLoading } =
    useGetTreatmentState(SALE_RIBBON);
  const [isLoading, setIsLoading] = useState(true);
  const { isMobile } = useViewport();

  const { showEquinoxBanner } = useEquinox();
  const { user } = useBezelUser();

  const buyerProfile = user?.buyerProfile;

  const { data: selectedSaleData, isValidating } = useGetSelectedSale(selectedSaleRibbonEnabled);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const isAuctionsMobile = isMobile && router.pathname.includes('auctions');

  if (isLoading || isValidating || selectedSaleRibbonLoading || isAuctionsMobile) {
    return null;
  }

  if (showEquinoxBanner) {
    return <EquinoxRibbon />;
  } else if (router.pathname.startsWith('/sell') && user?.sellerProfile) {
    return <SellerProfileRibbon />;
  } else if (selectedSaleRibbonEnabled && selectedSaleData?.rows && selectedSaleData?.rows?.length > 0) {
    return <SelectedSaleRibbon data={selectedSaleData} />;
  } else if (buyerProfile) {
    return <CustomRibbon buyerProfile={buyerProfile} />;
  }

  return <DefaultRibbon />;
};
