import { isWindowDefined } from './helpers';
import { isLocalStorageAvailable, isSessionStorageAvailable } from './isStorageAvailable';

type Storage = 'sessionStorage' | 'localStorage';

const storageAvailability: Record<Storage, boolean | undefined> = {
  sessionStorage: isSessionStorageAvailable,
  localStorage: isLocalStorageAvailable
};

export const getFromStorage = (key: string, storage: Storage = 'sessionStorage') => {
  if (!isWindowDefined || !storageAvailability[storage]) {
    return;
  }

  return window?.[storage]?.getItem(key);
};

export const setToStorage = (key: string, value: string, storage: Storage = 'sessionStorage') => {
  if (!isWindowDefined || !storageAvailability[storage]) {
    return;
  }

  window?.[storage]?.setItem(key, value);
};

export const removeFromStorage = (key: string, storage: Storage = 'sessionStorage') => {
  if (!isWindowDefined || !storageAvailability[storage]) {
    return;
  }

  window?.[storage]?.removeItem(key);
};
