import TagManager from 'react-gtm-module';

import { Analytics } from 'context/analytics';
import { Listing, ListingImageType, User } from 'types';

import { fireTrackEvent, getAttributionToken } from './helpers';
import { ListingPayload } from './types';

export const logViewListing = (analytics: Analytics, listing: Listing) => {
  const model = listing.model;
  const attributionToken = getAttributionToken();

  const payload: ListingPayload = {
    modelId: model.id,
    modelName: model.displayName,
    brandName: model.brand.displayName,
    referenceNumber: model.referenceNumber,
    listingId: listing.id,
    condition: listing.condition,
    manufactureYear: listing.manufactureYear,
    frontImageUrl: listing.images.find(i => i.type === ListingImageType.FRONT)?.image?.optimizedUrl,
    price: (listing.priceCents / 100.0).toFixed(2),
    currency: 'USD',
    offersAllowed: listing.activePricingModel === 'AUCTION' ? false : listing.offersAllowed,
    activePricingModel: listing.activePricingModel
  };

  if (attributionToken) {
    payload.attributionToken = attributionToken;
  }

  fireTrackEvent(analytics, 'View Listing', payload);
};

export const logGTMViewListing = (listing: Listing, user?: User) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'view_item',
      ecommerce: {
        items: [
          {
            currency: 'USD',
            item_id: listing.id,
            item_name: listing.model.displayName,
            item_brand: listing.model.brand.displayName,
            price: listing.priceCents / 100.0,
            activePricingModel: listing.activePricingModel,
            quantity: 1
          }
        ]
      },
      ...(user && {
        email: user.email,
        userId: user.id
      })
    }
  });
};
