import { useEffect } from 'react';
import { MdDone } from '@react-icons/all-files/md/MdDone';
import { MdOutlineClose } from '@react-icons/all-files/md/MdOutlineClose';
import { useRouter } from 'next/router';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Button } from 'components/design-system/Button';
import { Slideout, SlideoutCommonProps } from 'components/design-system/Slideout';
import { Spinner } from 'components/design-system/Spinner';
import { EquinoxEnrollmentState, useEquinox } from 'hooks/useEquinox';

const loadingPillContainerStyle = { background: 'rgba(0, 0, 0, 0.1)' };
const loadingPillSpinnerContainerStyle = { background: 'rgba(0, 0, 0, 0.2)' };

const successPillContainerStyle = { background: 'rgba(7, 116, 91, 0.1)', color: 'rgb(7, 116, 91)' };
const successPillSpinnerContainerStyle = { background: 'rgba(7, 116, 91, 0.2)' };

const errorPillContainerStyle = { background: 'rgba(241, 94, 94, 0.1)', color: 'rgb(241, 94, 94)' };
const errorPillSpinnerContainerStyle = { background: 'rgba(241, 94, 94, 0.2)' };

const goToHomepage = () => (window.location.href = '/');

export const EquinoxEnrollmentCompletionModal = (props: SlideoutCommonProps) => {
  const { enroll, enrollmentState } = useEquinox();
  const router = useRouter();
  const onHomepage = router.pathname === '/';

  useEffect(() => {
    enroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const continueBrowsing = () => {
    router.reload();
  };

  const onChat = () => {
    window.Intercom('showNewMessage');
    if (props.closeModal) {
      props.closeModal();
    }
  };

  return (
    <Slideout canClose={false} {...props} title>
      <div className='w-100 h-100 d-flex pb-5 flex-column justify-content-center align-items-center gap-5'>
        <CloudinaryImage src='equinox-bezel-logo.png' alt='Equinox - Bezel Logo' width={250} height={20} />
        {enrollmentState === EquinoxEnrollmentState.ENROLLING && (
          <div className='d-flex flex-column align-items-center gap-5'>
            <div className='rounded-3 d-flex align-items-center p-2' style={loadingPillContainerStyle}>
              <div className='rounded-3 p-2 text-black' style={loadingPillSpinnerContainerStyle}>
                <Spinner animation='border' />
              </div>
              <div className='text-uppercase px-3 riforma-regular text-black'>Pairing accounts</div>
            </div>
            <div className='text-center text-primary fs-20px line-height-28px px-0 px-md-5'>
              We&apos;re working on connecting your accounts...
            </div>
          </div>
        )}
        {enrollmentState === EquinoxEnrollmentState.ENROLLMENT_SUCCESSFUL && (
          <div className='d-flex flex-column align-items-center gap-5'>
            <div className='rounded-3 d-flex align-items-center p-2' style={successPillContainerStyle}>
              <div className='rounded-3 p-2' style={successPillSpinnerContainerStyle}>
                <MdDone size={24} />
              </div>
              <div className='text-uppercase px-3 riforma-regular'>Accounts Paired</div>
            </div>
            <div className='text-center text-primary fs-20px line-height-28px px-0 px-md-5'>
              Welcome to the Bezel | Equinox experience!
            </div>
            <div className='d-flex flex-column align-items-center w-100 w-md-75 gap-1'>
              <Button variant='primary' className='py-3 w-100' onClick={goToHomepage}>
                <span className='riforma-medium'>Start exploring</span>
              </Button>
              {!onHomepage && (
                <Button
                  variant='link'
                  className=' pt-3 w-100 text-primary text-decoration-none'
                  onClick={continueBrowsing}
                >
                  <span className='riforma-medium'>Continue Browsing</span>
                </Button>
              )}
            </div>
          </div>
        )}
        {enrollmentState === EquinoxEnrollmentState.ENROLLMENT_FAILED && (
          <div className='d-flex flex-column align-items-center gap-5'>
            <div className='rounded-3 d-flex align-items-center p-2' style={errorPillContainerStyle}>
              <div className='rounded-3 p-2' style={errorPillSpinnerContainerStyle}>
                <MdOutlineClose size={24} />
              </div>
              <div className='text-uppercase px-3 riforma-regular'>Account error</div>
            </div>
            <div className='text-center text-primary fs-20px line-height-28px px-0 px-md-5'>
              There was an issue linking your accounts
            </div>
            <div className='d-flex flex-column align-items-center w-100 w-md-75 gap-1'>
              <Button variant='primary' className='py-3 w-100' onClick={onChat}>
                <span className='riforma-medium'>Chat with us</span>
              </Button>
              <Button
                variant='link'
                className='pt-3 w-100 text-primary text-decoration-none'
                onClick={continueBrowsing}
              >
                <span className='riforma-medium'>Continue Browsing</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </Slideout>
  );
};
