import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { BREAKPOINTS } from 'consts';

interface ViewportContextInterface {
  isMobileSmall: boolean;
  isMobile: boolean;
  isTabletOrMobile: boolean;
  isDesktopOrSmaller: boolean;
  isDesktopLargeOrSmaller: boolean;
  isLoaded: boolean;
}

const defaultContext: ViewportContextInterface = {
  isMobileSmall: false,
  isMobile: false,
  isTabletOrMobile: false,
  isDesktopOrSmaller: false,
  isDesktopLargeOrSmaller: false,
  isLoaded: false
};

export const ViewportContext = createContext<ViewportContextInterface>(defaultContext);

interface ViewportContextProviderProps extends PropsWithChildren {}

export const ViewportContextProvider: React.FC<ViewportContextProviderProps> = ({ children }) => {
  const isMobileSmall = useMediaQuery({ query: `(max-width: ${BREAKPOINTS.xs}px)` });
  const isMobile = useMediaQuery({ query: `(max-width: ${BREAKPOINTS.sm}px)` });
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${BREAKPOINTS.md}px)` });
  const isDesktopOrSmaller = useMediaQuery({ query: `(max-width: ${BREAKPOINTS.lg}px)` });
  const isDesktopLargeOrSmaller = useMediaQuery({ query: `(max-width: ${BREAKPOINTS.xlg}px)` });
  const [returnValue, setReturnValue] = useState<ViewportContextInterface>(defaultContext);

  useEffect(() => {
    setReturnValue({
      isMobileSmall,
      isMobile,
      isTabletOrMobile,
      isDesktopOrSmaller,
      isDesktopLargeOrSmaller,
      isLoaded: true
    });
  }, [isMobile, isMobileSmall, isTabletOrMobile, isDesktopOrSmaller, isDesktopLargeOrSmaller]);

  const value = useMemo(() => returnValue, [returnValue]);

  return <ViewportContext.Provider value={value}>{children}</ViewportContext.Provider>;
};

export const useViewport = () => useContext(ViewportContext);
