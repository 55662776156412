import { Nav } from 'react-bootstrap';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { Link } from 'components/common/Link';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/TopNav.module.scss';
import { StyledComponent } from 'types';

export const NavlinkShopTestIds = {
  dropdownEnabledContainer: 'nav-link-dropdown-enabled-container',
  dropdownDisabledContainer: 'nav-link-dropdown-disabled-container'
};

export interface NavlinkShopProps extends StyledComponent {
  onMouseMove: (e: React.MouseEvent) => void;
  onMouseLeave: (e: React.MouseEvent) => void;
  onClick: (e: React.MouseEvent) => void;
  isActive?: boolean;
  dropdownEnabled?: boolean;
  classes?: string;
}

export const NavlinkShop: React.FC<NavlinkShopProps> = ({
  onMouseMove,
  classes,
  onMouseLeave,
  onClick,
  isActive,
  dropdownEnabled
}) => {
  const router = useRouter();
  const isOnExplorePage = router.pathname.includes('/explore');

  return dropdownEnabled ? (
    <div
      data-testid={NavlinkShopTestIds.dropdownEnabledContainer}
      className={classNames(classes, styles.navItem, 'nav-link', 'cursor-pointer ', {
        [styles.active]: isActive,
        'text-dark': isOnExplorePage
      })}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      Shop
    </div>
  ) : (
    <div data-testid={NavlinkShopTestIds.dropdownDisabledContainer} className={styles.border}>
      {/* legacyBehavior has to be added because Nav.Link adds an extra a tag */}
      <Link legacyBehavior passHref href={pageRoutes.explore()}>
        <Nav.Link
          active={isOnExplorePage}
          className={classNames(classes, {
            'opacity-100': isOnExplorePage
          })}
        >
          Shop
        </Nav.Link>
      </Link>
    </div>
  );
};
