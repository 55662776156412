import { FC, FormEvent } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { FaMagnifyingGlass } from '@react-icons/all-files/fa6/FaMagnifyingGlass';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { useTheme } from 'context/theme';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/Search.module.scss';

import { useSearchAnalytics } from '../../hooks/analytics/useSearchAnalytics';

export const SearchBarTestIds = {
  container: 'search-bar-container',
  queryInput: 'search-bar-query-input'
};

export type SearchBarProps = {
  query: string;
  setQuery: (val: string) => void;
  setHasNavigated: () => void;
};

/**
 * Search bar, no logic except for receiving the [query] state from its parent and
 * lifting the updated query state back to it.
 */
export const SearchBar: FC<SearchBarProps> = ({ query, setQuery, setHasNavigated }) => {
  const router = useRouter();
  const { triggerSearchInteractionEvents } = useSearchAnalytics({
    query
  });
  const { isDark } = useTheme();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    triggerSearchInteractionEvents();
    if (query && query.length > 0) {
      setHasNavigated();
      router.push(pageRoutes.explore({ searchQuery: encodeURIComponent(query) }));
    }
  };

  return (
    <Form
      className='d-flex align-items-center text-secondary'
      onSubmit={onSubmit}
      data-testid={SearchBarTestIds.container}
    >
      <FaMagnifyingGlass
        className={classNames('position-relative text-primary', {
          [styles.searchIconDark]: isDark
        })}
        style={{ left: '30px' }}
      />
      <FormControl
        type='search'
        name='search'
        placeholder='Search models, references, brands'
        className={classNames(
          'riforma-regular mx-auto fs-14px rounded-2 text-primary ps-5 pe-3 py-2 border border-muted shadow-none bg-transparent',
          {
            [styles.form]: !isDark,
            [styles.formDark]: isDark
          }
        )}
        onChange={e => setQuery(e.target.value)}
        value={query}
        data-testid={SearchBarTestIds.queryInput}
      />
    </Form>
  );
};
